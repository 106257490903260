import { memo } from "react";
import { Run, SpatialRun, TypeRun } from "../types";

import "./run-details.module.css";

const dateString = (dt: Date): string => {
  const d = new Date(dt);
  if (d.getFullYear() < 2022) return "N/A";
  return (
    d.getFullYear() +
    "-" +
    String(d.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(d.getDate()).padStart(2, "0")
  );
};

const dateTimeString = (dt: Date): string => {
  if (!dt) return "";
  const d = new Date(dt);
  if (d.getFullYear() < 2022) return "";
  return (
    dateString(dt) +
    " " +
    String(d.getHours()).padStart(2, "0") +
    ":" +
    String(d.getMinutes()).padStart(2, "0")
  );
};

const labelColor = "DarkGrey";

type BarcodeProps = {
  title: string;
  partNumber;
  lotNumber;
  serialNumber: string;
  expiration: Date;
};

const Barcodes = ({
  title,
  partNumber,
  lotNumber,
  serialNumber,
  expiration,
}: BarcodeProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: 240,
        marginLeft: 30,
        marginRight: 30,
      }}
    >
      <div
        style={{
          width: "100%",
          textAlign: "center",
          color: labelColor,
          marginBottom: 5,
        }}
      >
        <span>{title}</span>
      </div>
      <div style={{ width: "50%" }}>Lot Number:</div>
      <div style={{ width: "50%" }}>{lotNumber}</div>
      <div style={{ width: "50%" }}>Part Number:</div>
      <div style={{ width: "50%" }}>{partNumber}</div>
      <div style={{ width: "50%" }}>Serial Number:</div>
      <div style={{ width: "50%" }}>{serialNumber}</div>
      <div style={{ width: "50%" }}>Expiration:</div>
      <div style={{ width: "50%" }}>{dateString(expiration)}</div>
    </div>
  );
};

type TelemetryRunDetailsProps = {
  run: Run | SpatialRun;
  typeRun: TypeRun;
};

const RunDetails = memo(({ run, typeRun }: TelemetryRunDetailsProps) => {
  // const run = currentRun as Run;
  const spatialRun = run as SpatialRun;
  const sequencingRun = run as Run;

  if (!run) return <div />;

  const recipeValues = [];
  if (run.recipeValues) {
    const recipeValuesSplit = run.recipeValues.split("^^^");
    for (const value of recipeValuesSplit) {
      const splitValue = value.split("###");
      recipeValues.push({ key: splitValue[0], value: splitValue[1] });
    }
  }
  console.log(spatialRun);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <h2 style={{ marginBottom: 0, color: "black" }}>{run?.runName}</h2>
      <span style={{ marginBottom: 10 }}>{dateTimeString(run?.Date)}</span>
      <span>
        {typeRun === "Spatial" && spatialRun.batches.length > 0 ? (
          <>
            {spatialRun.batches.map((v) => v.Name + ":" + v.Cycles).join(", ")}
          </>
        ) : (
          <>
            {" "}
            {sequencingRun?.r1}+{sequencingRun?.r2},{sequencingRun?.i1}+
            {sequencingRun?.i2}
          </>
        )}
      </span>
      <h4 style={{ color: "black" }}>
        {run?.instrumentName} {run?.side}
      </h4>

      {`${run?.lastStatus} (${dateTimeString(run?.lastStatusUpdated)})`}

      <div style={{ marginTop: 40, marginBottom: 40 }}>
        {/* <strong style={{ color: labelColor }}>Description:</strong> */}
        <div
          style={{
            marginTop: 8,
            borderColor: "gray",
            borderWidth: 1,
            borderStyle: "solid",
            padding: 8,
          }}
        >
          {run?.description}
        </div>
      </div>
      <br />

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Barcodes
          title="Flowcell"
          partNumber={run?.flowcellPartNumber}
          lotNumber={run?.flowcellLotNumber}
          serialNumber={run?.flowcellSerialNumber}
          expiration={run?.flowcellExpiration}
        />

        {typeRun === "Sequencing" ? (
          <Barcodes
            title="Reagent"
            partNumber={sequencingRun?.reagentPartNumber}
            lotNumber={sequencingRun?.reagentLotNumber}
            serialNumber={sequencingRun?.reagentSerialNumber}
            expiration={sequencingRun?.reagentExpiration}
          />
        ) : (
          <Barcodes
            title="Panel Cartridge"
            partNumber={spatialRun?.panelPartNumber}
            lotNumber={spatialRun?.panelLotNumber}
            serialNumber={spatialRun?.panelSerialNumber}
            expiration={spatialRun?.panelExpiration}
          />
        )}

        <Barcodes
          title="Buffer"
          partNumber={run?.bufferPartNumber}
          lotNumber={run?.bufferLotNumber}
          serialNumber={run?.bufferSerialNumber}
          expiration={run?.bufferExpiration}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: 340,
          marginLeft: 30,
          marginRight: 30,
          marginTop: 80,
        }}
      >
        <div style={{ width: "50%", color: labelColor }}>
          Control SW Version:
        </div>
        <div style={{ width: "50%" }}>{run?.version}</div>
        <div style={{ width: "50%", color: labelColor }}>Instrument ID:</div>
        <div style={{ width: "50%" }}>{run?.instrumentID}</div>
        <div style={{ width: "50%", color: labelColor }}>
          Instrument Serial Number
        </div>
        <div style={{ width: "50%" }}>{run?.instrumentSerialNumber}</div>
        <div style={{ width: "50%", color: labelColor }}>Num Tiles:</div>
        <div style={{ width: "50%" }}>{run?.numTiles}</div>
        <div style={{ width: "50%", color: labelColor }}>Tenant ID:</div>
        <div style={{ width: "50%" }}>{run?.tenantID}</div>
        <div style={{ width: "50%", color: labelColor }}>
          Tile Selection Module:
        </div>
        <div style={{ width: "50%" }}>{run?.tileSelectionModule}</div>
        <div style={{ width: "50%", color: labelColor }}>
          {run?.batches ? "Num Batches: " : "Library Pools:"}
        </div>
        <div style={{ width: "50%" }}>
          {run?.batches ? run?.batches.length : sequencingRun?.libraryPools}
        </div>

        <div
          style={{
            width: recipeValues.length > 0 ? "100%" : "50%",
            color: labelColor,
            textAlign: recipeValues.length > 0 ? "center" : "left",
          }}
        >
          Recipe Values:
        </div>
        {recipeValues.length > 0 && (
          <table
            style={{
              width: "100%",
              wordWrap: "break-word",
              borderCollapse: "collapse",
            }}
          >
            <tr
              style={{
                textAlign: "center",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <th style={{ border: "1px solid black" }}> Key </th>
              <th style={{ border: "1px solid black" }}> Value </th>
            </tr>
            {recipeValues.map((value, index) => (
              <tr key={index} style={{ textAlign: "left" }}>
                <td
                  style={{
                    width: "50%",
                    wordBreak: "break-all",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  {value.key}{" "}
                </td>
                <td
                  style={{
                    width: "50%",
                    wordBreak: "break-all",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  {value.value}{" "}
                </td>
              </tr>
            ))}
          </table>
        )}
        {recipeValues.length === 0 && (
          <div style={{ width: "50%" }}> None </div>
        )}
      </div>
    </div>
  );
});

export default RunDetails;
