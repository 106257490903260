import {
  memo,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { SelectBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";

import {
  Chart,
  CommonSeriesSettings,
  ConstantLine,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Label,
  ArgumentAxis,
  Tooltip,
  ZoomAndPan,
} from "devextreme-react/chart";
import { ProdStatsType } from "./run-container";
import { decompressFloat32Array } from "src/utils/decompress";

type CycleChartProps = {
  data: ProdStatsType;
  reads: { read: string; cycles: number }[];
  isLoading: boolean;
};

const q30Range = [0, 100];
const errorRange = [0, 20];
const displayItems = ["Q30", "Q40", "Q50", "AvgQ", "Error"];

const CycleChart = memo(({ data, reads, isLoading }: CycleChartProps) => {
  const chart = useRef();
  const [display, setDisplay] = useState<
    "Error" | "Q30" | "Q40" | "Q50" | "AvgQ"
  >("Q30");
  const [libraryPoolDisplay, setLibraryPoolDisplay] = useState<
    "Run Level" | "Lane 1" | "Lane 2"
  >("Run Level");

  const [avgLine, setAvgLine] = useState(null);
  const containerDiv = useRef();
  const [plotData, setPlotData] = useState([]);

  useEffect(() => {
    if (data && display) {
      const values = [];
      let field =
        display[0].toLowerCase() + display.slice(1, display.length) + "ByCycle";
      if (libraryPoolDisplay !== "Run Level") field += "ByLane";
      const str = data[field];

      let vals = decompressFloat32Array(str);

      let cycle = 1;

      const totalCycles = reads.map((v) => v.cycles).reduce((p, c) => p + c, 0);

      for (let ri = 0; ri < reads.length; ri++) {
        let baseCycle = libraryPoolDisplay === "Lane 2" ? totalCycles : 0;
        const tmp = ["R1", "R2", "I1", "I2"]; // apparently always hardcoded in this order
        for (let i = 0; i < tmp.length; i++) {
          if (tmp[i] === reads[ri].read) break;
          baseCycle += reads.find((v) => v.read === tmp[i])?.cycles || 0;
        }

        for (let c = 0; c < reads[ri].cycles; c++) {
          const absoluteCycle = baseCycle + c;
          let value = vals[absoluteCycle];
          if (value < 0) value = NaN;
          values.push({ cycle, value, read: reads[ri].read });
          cycle++;
        }
      }
      setPlotData(values);
    } else {
      setPlotData([]);
    }
  }, [data, libraryPoolDisplay, display]);

  useEffect(() => {
    if (chart.current && data)
      (chart.current as any).instance.resetVisualRange();
  }, [chart, data]);

  useEffect(() => {
    chart.current && (chart.current as any).instance.render();
  }, []);

  useEffect(() => {
    let constLineColor = "gold";
    let constLineAvg = 0;
    let lines = [];

    if (
      plotData &&
      plotData.length > 0 &&
      ["Q15", "Q40", "Q30", "Q50", "Error", "AvgQ"].indexOf(display) >= 0
    ) {
      const allSeries = (chart.current as any).instance.getAllSeries();

      for (let i = 0; i < allSeries.length; i++) {
        const series = allSeries[i];
        // if (i > 3) series.hide();
        constLineColor = series.getColor();
        const pts = series.getAllPoints();
        let avg = 0.0;
        for (let i = 0; i < pts.length; i++) {
          avg = avg + pts[i].value;
        }
        constLineAvg = avg / pts.length;

        lines.push(
          <ConstantLine
            width={1}
            key={`constantLine${i}`}
            value={constLineAvg}
            color={constLineColor}
            dashStyle="dash"
          >
            <Label
              font={{ color: constLineColor }}
              text={constLineAvg.toPrecision(4)}
              horizontalAlignment={series.name === "R1" ? "left" : "right"}
              verticalAlignment="bottom"
            />
          </ConstantLine>
        );
      }
    }
    setAvgLine(lines);
  }, [plotData, display, libraryPoolDisplay]);

  const renderTooltip = useCallback(
    (pointInfo) => {
      if (pointInfo && pointInfo.point && pointInfo.point.data) {
        const data = pointInfo.point.data;
        const label3 = data.Label;
        return (
          <div>
            {label3}
            <br />
            {display}: {pointInfo.value.toPrecision(4)}
          </div>
        );
      }
      return <div />;
    },
    [display]
  );

  return (
    <div ref={containerDiv} style={{ marginTop: 40 }}>
      <LoadPanel
        position={{ of: "#chart" }}
        visible={isLoading}
        showIndicator={true}
        showPane={true}
      />

      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={displayItems}
              value={display}
              onValueChanged={(e) => {
                setDisplay(e.value);
              }}
            />
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={["Run Level", "Lane 1", "Lane 2"]}
              value={libraryPoolDisplay}
              onValueChanged={(e) => {
                setLibraryPoolDisplay(e.value);
              }}
            />
          </td>
        </tr>
      </table>

      <Chart
        id="chart"
        ref={chart}
        height="70vh"
        onLegendClick={useCallback((e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }, [])}
        dataSource={plotData}
        animation={false}
      >
        <CommonSeriesSettings
          argumentField="cycle"
          valueField="value"
          type="line"
        >
          <Point size={4} />
        </CommonSeriesSettings>
        <SeriesTemplate nameField="read" />
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis
          title={display.includes("Av") ? "Q Score" : display + " (%)"}
          wholeRange={
            display === "Q30" || display === "Q40" || display === "Q50"
              ? q30Range
              : display === "Error"
              ? errorRange
              : null
          }
        >
          {avgLine}
        </ValueAxis>
        <ArgumentAxis title="Cycle" allowDecimals={true} />
        <Legend
          visible={true}
          position="outside"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Export enabled={true} />
      </Chart>
      {/* {display === "Error" && (
        <div>
          {runMetricsData?.ReadMetrics?.map((v) => (
            <div key={v.Read}>
              PhiX Alignment Percent {v.Read}:{" "}
              {v.RunPhixAlignmentRate?.toPrecision(3)}%
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
});

export default CycleChart;
