import { useEffect, useRef, useCallback, useMemo, useState } from "react";
import TreeView from "devextreme-react/tree-view";
import { navigation } from "../../app-navigation";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import { useAuth } from "src/contexts/auth";
import "./side-navigation-menu.scss";

import * as events from "devextreme/events";

var jwtDecode = require("jwt-decode");

export default function (props) {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;

  const [hasPermission, setHasPermission] = useState(null);
  const [hasCustomerAccess, setHasCustomerAccess] = useState(false);
  const { getAccessTokenSilently, user } = useAuth();
  const { isLarge } = useScreenSize();

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      var decoded = jwtDecode(token);
      console.log("decoded", decoded);
      const { permissions } = decoded;
      const customerAccess = permissions.includes("read.all");
      setHasCustomerAccess(customerAccess);
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    console.log("user", user);
    setHasPermission(user && user["https://elembio.io/executive"]);
  }, [user]);

  const items = useMemo(
    () => {
      return navigation(hasPermission, hasCustomerAccess).map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item, expanded: isLarge };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPermission, hasCustomerAccess]
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    // if (compactMode) {
    //   treeView.collapseAll();
    // }
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container"}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          expanded
          width={"100%"}
        />
      </div>
    </div>
  );
}
