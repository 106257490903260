import React, { useEffect, useState } from "react";

import Scheduler, { Resource } from "devextreme-react/scheduler";

import { fetchWithToken } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";
import { BusyOverlay } from "src/components/BusyOverlay";
import { SelectBox } from "devextreme-react";

const currentDate = new Date();
const groups = ["side"];

const editing = {
  allowAdding: false,
  allowDeleting: false,
  allowResizing: false,
  allowDragging: false,
  allowUpdating: false,
};

interface InstrumentSideType {
  tenant: string;
  text: string;
  id: number;
  color: string;
}

interface ItemType {
  text: string;
  type: number;
  startDate: Date;
  endDate: Date;
  side: number;
  tenant: string;
}

interface UtilizationRun {
  tenant;
  instrumentName;
  side;
  runType;
  text;
  runName: string;
  startDate;
  endDate: Date;
}

export const UtilizationPlanner = () => {
  const [data, setData] = useState<ItemType[]>([]);
  const [instrumentSideData, setInstrumentSideData] = useState<
    InstrumentSideType[]
  >([]);

  const [busy, setBusy] = useState(false);

  const { getAccessTokenSilently } = useAuth();
  const [selectedTenant, setSelectedTenant] = useState("");
  const [tenants, setTenants] = useState<string[]>([]);

  useEffect(() => {
    fetchWithToken(getAccessTokenSilently, "/utilizationTenants").then((resp) =>
      setTenants(resp)
    );
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!selectedTenant) return;
    setBusy(true);
    fetchWithToken(
      getAccessTokenSilently,
      "/utilizationRuns?tenant=" + selectedTenant
    )
      .then((resp: UtilizationRun[]) => {
        const instrumentMap: Map<string, number> = new Map();
        const instrumentSides: InstrumentSideType[] = [];
        const runs: ItemType[] = [];

        for (let i = 0; i < resp.length; i++) {
          const tenant = resp[i].tenant;
          const instr = resp[i].instrumentName;
          if (!instrumentMap.get(instr)) {
            const len = instrumentSides.length;
            instrumentMap.set(instr, len + 1);
            instrumentSides.push({
              tenant,
              text: instr + " A\n" + tenant,
              id: len + 1,
              color: "#1e90ff",
            });
            instrumentSides.push({
              tenant,
              text: instr + " B\n" + tenant,
              id: len + 2,
              color: "#ff9747",
            });
          }
          const instrOffset = instrumentMap.get(instr);
          let currentType = 1;
          switch (resp[i].runType) {
            case "Prime":
              currentType = 1;
              break;
            case "Wash":
              currentType = 2;
              break;
            case "Sequencing":
              currentType = 3;
              break;
            default:
              currentType = 4;
              break;
          }
          runs.push({
            side: resp[i].side === "A" ? instrOffset : instrOffset + 1,
            tenant,
            text: resp[i].runName,
            type: currentType,
            startDate: resp[i].startDate,
            endDate: resp[i].endDate,
          });
        }
        setData(runs);
        setInstrumentSideData(instrumentSides);
      })
      .finally(() => setBusy(false));
  }, [getAccessTokenSilently, selectedTenant]);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: 800,
          alignItems: "center",
          marginTop: 50,
          marginBottom: 50,
          gap: 30,
        }}
      >
        <div>Tenant:</div>
        <SelectBox
          dataSource={tenants}
          value={selectedTenant}
          onValueChanged={(e) => setSelectedTenant(e.value)}
        />
      </div>
      <div
        style={{
          background: "white",
          paddingLeft: 100,
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <Scheduler
          timeZone="America/Los_Angeles"
          currentView="timelineWeek"
          dataSource={data}
          defaultCurrentDate={currentDate}
          height={100 + instrumentSideData.length * 80}
          views={["timelineWeek"]}
          width="1500px"
          groups={groups}
          editing={editing}
          cellDuration={60 * 24}
          firstDayOfWeek={1}
          startDayHour={0}
          endDayHour={24}
        >
          <Resource
            fieldExpr="type"
            allowMultiple={false}
            dataSource={runTypeData}
            label="Type"
            useColorAsDefault={true}
          />
          <Resource
            fieldExpr="side"
            allowMultiple={false}
            dataSource={instrumentSideData}
            label="Instrument Side"
          />
        </Scheduler>
        {busy && <BusyOverlay />}
      </div>
    </>
  );
};

const runTypeData = [
  {
    text: "Prime",
    id: 1,
    color: "#cb6bb2",
  },
  {
    text: "Wash",
    id: 2,
    color: "#56ca85",
  },
  {
    text: "Sequence",
    id: 3,
    color: "#1e90ff",
  },
  {
    text: "R&D",
    id: 4,
    color: "#ff9747",
  },
];
